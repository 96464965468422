@font-face {
  font-family: "DINCondensed-Bold";
  src: url("./font/DIN-Bold.ttf") format("truetype");
  font-style: normal;
}
h1 {
  margin: 0;
}
:root {
  --main-color: #16b0b0;
}

#root {
  height: 100vh;
  box-sizing: border-box;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;

  /* overflow-y: scroll;
  height: 100%; */
  width: 100vw;
  box-sizing: border-box;
}

.heart-width {
  width: 1200px;
}

.min-heart-width {
  min-width: 1200px;
}

div {
  display: block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

img {
  -webkit-user-drag: none;
}

.one-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
